import "./SuperGhosts.css";
import React from "react";
export default class SuperGhosts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            randomImage1: this.getRandomGhostURL(),
            randomImage2: this.getRandomGhostURL(),
            randomImage3: this.getRandomGhostURL(),
        }
    }
    renderGhost(responsive, url) {
        return (
            <div class="col-md-3">
                <div class="icon-box">
                    <img
                        class={responsive ? "ghostImage responsiveGhost" : "ghostImage"}
                        src={url}
                        alt="Super Ghost"
                    />
                </div>
            </div>
        );
    }
    getRandomGhostURL() {
        const random = Math.floor(Math.random() * 83);
        return process.env.PUBLIC_URL + "/img/superGhosts/" + random + ".png";
    }
    render() {
        return (
            <section id="superghosts" class="d-flex align-items-center justify-content-center">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8">
                            <h1>Super Ghosts</h1>
                            <h2><span>The next step for your Ghosts</span></h2>
                        </div>
                    </div>

                    <div class="row gy-4 mt-5 justify-content-center d-flex align-items-center ">
                        <h2>Somewhere in the Avalanche chain ...<br /></h2>
                        <h2 style={{ "text-align": "justify" }}>

                            We were lost in this ocean of previously unexplored blocks, wandering in this digital desert.

                            When we met these strange Avax Ghosts!They were the coolest pixelized Ghosts we have ever seen.

                            They are for us, NFT holders, our avatars, our companions.

                            However, at that moment, we had discovered something extraordinary, unique to the Avax Ghosts..The fusion!

                            Yes!Avax Ghosts can merge and thus become SuperGhosts!Completely new in every way!

                            This is all amazing but there is a price  Avax Ghosts are immortal but cannot reproduce.

                            This means that they disappear as new Super Ghosts are created.</h2>
                    </div>

                    <div class="row gy-4 mt-5 justify-content-center align-items-center">

                        {this.renderGhost(false, this.state.randomImage1)}

                        {this.renderGhost(false, this.state.randomImage2)}

                        {this.renderGhost(true, this.state.randomImage3)}

                        <a href="/superghosts"> <button class="superGhostMint-btn">Mint Super Ghost</button></a>
                    </div>
                </div>
            </section >
        );
    }
}