import "./Mint.css";
import React from "react";
import GhostsContract from "../abi/Ghosts.json";
import Web3 from "web3";

const states = {
    "notStarted": "Mint",
    "approving": "Approving...",
    "confirming": "Waiting for Confirmation on Blockchain"
}
const mintingEnabled = true;
const contractaddress = "0xBe7B8A35cce4b0cE610213ee382B823a96D4A89D";
const web3 = new Web3(window.ethereum);
const contractInstance = new web3.eth.Contract(GhostsContract.abi, contractaddress);
const allowedChainId2 = 0xA86A;
const allowedChainId = 0xa86a;
if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('accountsChanged', () => {
        window.location.reload();
    });
    window.ethereum.on('chainChanged', () => {
        window.location.reload();
    });
}
export default class Mint extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentMintStatus: "notStarted",
            currentlyMinted: "Loading...",
            contractAllowsMinting: false,
        }
    }

    handleMintBtnOnClick = (eip1559) => {
        const amount = document.getElementById("amountInput").value;
        this.setState({
            currentMintStatus: "approving"
        });
        const eip1559Transaction = {
            from: this.props.connectedAccount,
            value: 2000000000000000000 * amount,
        };
        const legacyTransaction = {
            from: this.props.connectedAccount,
            value: 2000000000000000000 * amount,
            type: "0x00",
            maxFeePerGas: null,
            maxPriorityFeePerGas: null
        };
        contractInstance.methods.mintGhosts(amount).send((eip1559 ? eip1559Transaction : legacyTransaction)).then(response => {
            this.setState({
                currentMintStatus: "notStarted",
            });
            this.getCurrentMintedGhosts();
        }).catch(e => {
            if (e.code === -32602) {
                this.handleMintBtnOnClick(false);
            } else {
                this.setState({
                    currentMintStatus: "notStarted",
                });
                console.log(e);
            }
        });
    }

    getCurrentMintedGhosts = () => {
        contractInstance.methods.totalSupply().call().then(response => {
            this.setState({ currentlyMinted: response });
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            if (this.props.isWeb3Connected) {
                if (typeof window.ethereum !== 'undefined' && (window.ethereum.chainId == allowedChainId || window.ethereum.chainId == allowedChainId2)) {
                    this.getCurrentMintedGhosts();
                    contractInstance.methods.saleIsActive().call().then(response => {
                        this.setState({ contractAllowsMinting: response });
                    });
                } else {
                    alert("Please connect to the Avalanche C-Chain");
                }
            }
        }
    }
    componentDidMount = () => {
        if (typeof window.ethereum !== 'undefined' && this.props.isWeb3Connected) {

            if (window.ethereum.chainId == allowedChainId || window.ethereum.chainId == allowedChainId2) {
                this.getCurrentMintedGhosts();
                contractInstance.methods.saleIsActive().call().then(response => {
                    this.setState({ contractAllowsMinting: response });
                });
            } else {
                alert("Please connect to the Avalanche C-Chain");
            }
        }
    }
    getMintSection = () => {
        if (typeof window.ethereum !== 'undefined') {
            if (window.ethereum.chainId == allowedChainId || window.ethereum.chainId == allowedChainId2 || !this.props.isWeb3Connected) {
                if (this.props.isWeb3Connected) {
                    if (mintingEnabled && this.state.contractAllowsMinting) {
                        return (
                            <div class="container">

                                <div class="row justify-content-center">
                                    <div class="col-xl-6 col-lg-8">
                                        <h1>Mint your own Ghost</h1>
                                        <h2>Contract Adress: <a target="_blank" rel="noreferrer" href={"https://cchain.explorer.avax.network/address/" + contractaddress}>{contractaddress}</a></h2>
                                    </div>
                                </div>

                                <div class="row gy-4 mt-5 justify-content-center">
                                    <div id="buttonGroup">

                                        <h2>Amount:</h2>
                                        <input id="amountInput" defaultValue="1" max="10" min="1" type="number" />
                                        <button class="mint-btn" disabled={this.props.isWeb3Connected ? "" : "disabled"} onClick={() => this.handleMintBtnOnClick(true)}>{states[this.state.currentMintStatus]}</button>
                                        <h2>{this.props.isWeb3Connected ? "" : "Please connect your Metamask"}</h2>
                                    </div>
                                </div>
                                <h2>Currently Minted: {this.state.currentlyMinted}/2'500</h2>
                            </div>
                        )
                    } else {
                        return <div class="container"><div class="row justify-content-center"><h1>Minting starts on the 26th of September!</h1></div></div >
                    }
                } else {
                    return <div class="container"><h1>Please connect your Metamask</h1></div>
                }
            } else {
                return <div class="container"><h1>Please connect to the Avalanche C-Chain</h1></div>;
            }
        }
        return <h1>Please install Metamask</h1>

    }
    render() {
        return (
            <section id="mint" class="d-flex align-items-center justify-content-center">
                {this.getMintSection()}
            </section >

        );
    }
}