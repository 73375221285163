import "./Socials.css";
import React from "react";

export default class Socials extends React.Component {

    render() {
        return (
            <section id="socials" class="socials">
                <div class="container">

                    <div class="section-title">
                        <p>Socials</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                            <a rel="noreferrer" href="https://twitter.com/AvaxGhosts" target="_blank" class="socialLinkBox icon-box">
                                <div class="icon"><img alt="Twitter" src="https://bankimooncentre.org/wp-content/uploads/2020/05/twitter-icon-square-logo-108D17D373-seeklogo.com_.png" /></div>
                                <h4><a rel="noreferrer" href="https://twitter.com/AvaxGhosts" target="_blank">Twitter</a></h4>
                            </a>
                        </div>
                    </div>

                </div>
            </section >
        );
    }
}