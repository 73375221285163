import './App.css';
import React from 'react';
import Navigation from './components/Navigation.jsx';
import Home from './components/Home.jsx';
import Mint from './components/Mint.jsx';
import SuperGhosts from './components/superGhosts/SuperGhosts';
import Team from './components/Team.jsx';
import Roadmap from './components/Roadmap';
import Socials from './components/Socials.jsx';
import detectEthereumProvider from '@metamask/detect-provider'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import SuperGhostsMint from './components/superGhosts/SuperGhostsMint';
class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      web3connected: false,
      web3accounts: []
    }
  }

  getConnectedAccount = () => {
    const account = this.state.web3accounts[0];
    if (account !== [])
      return account.substr(0, 6) + "..." + account.substr(38, 4);
  }

  handleConnectWeb3 = () => {
    detectEthereumProvider().then((provider) => {
      if (!this.state.web3connected) {
        if (typeof provider !== 'undefined') {
          try {
            const accounts = provider.request({ method: 'eth_requestAccounts' });
            accounts.then((acc) => {
              this.setState({ web3connected: true, web3accounts: acc });
            });
          } catch (e) {
            console.log("Metamask request denied");
          }
        } else {
          alert("Please install Metamask");
        }
      } else {
        this.setState({
          web3account: [],
          web3connected: false,
        });
      }
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/superghosts">
            <Navigation returnHome connectWeb3={this.handleConnectWeb3} getConnectedAccount={this.getConnectedAccount} isWeb3Connected={this.state.web3connected} />
            <SuperGhostsMint connectedAccount={this.state.web3accounts[0]} isWeb3Connected={this.state.web3connected} />
          </Route>
          <Route path="/">
            <div className="App" >
              <Navigation connectWeb3={this.handleConnectWeb3} getConnectedAccount={this.getConnectedAccount} isWeb3Connected={this.state.web3connected} />
              <Home />
              <Mint connectedAccount={this.state.web3accounts[0]} isWeb3Connected={this.state.web3connected} />
              <SuperGhosts />
              <Roadmap />
              <Team />
              <Socials />
            </div>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
