import "./SuperGhostsMint.css";
import React from "react";
import GhostsContract from "../../abi/Ghosts.json";
import SuperGhostsContract from "../../abi/SuperGhosts.json";
import Web3 from "web3";

const contractaddress = "0xBe7B8A35cce4b0cE610213ee382B823a96D4A89D";
const contractaddressSuperGhosts = "0xc5fE1170c52505e286c4f32BB5d960CaB749840f";
const web3 = new Web3(window.ethereum);
const contractInstance = new web3.eth.Contract(GhostsContract.abi, contractaddress);
const contractInstanceSuperGhosts = new web3.eth.Contract(SuperGhostsContract.abi, contractaddressSuperGhosts);
const allowedChainId2 = 0xA86A;
const allowedChainId = 0xa86a;
if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('accountsChanged', () => {
        window.location.reload();
    });
    window.ethereum.on('chainChanged', () => {
        window.location.reload();
    });
}
export default class SuperGhostsMint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedGhost1: "",
            selectedGhost2: "",
            ownedGhosts: [],
            contractAllowsMinting: false,
            currentMintStatus: "notStarted",
            isApproved: false,
        }
    }

    onGhostSelect = (number, selected) => {
        if (number === 1) {
            this.setState({
                selectedGhost1: selected.options[selected.options.selectedIndex].value
            });
        } else {
            this.setState({
                selectedGhost2: selected.options[selected.options.selectedIndex].value
            });
        }
    }

    getGhostList = () => {
        const ghostList = [];
        this.state.ownedGhosts.forEach(element => {
            ghostList.push(<option key={"Ghostoption-" + element} value={element}>{"Ghost " + element}</option>);
        });
        return ghostList;
    }

    handleApproveBtnOnClick = (eip1559) => {
        this.setState({
            currentMintStatus: "approving"
        });
        const eip1559Transaction = {
            from: this.props.connectedAccount,
        };
        const legacyTransaction = {
            from: this.props.connectedAccount,
            type: "0x00",
            maxFeePerGas: null,
            maxPriorityFeePerGas: null
        };
        contractInstance.methods.setApprovalForAll(contractaddressSuperGhosts, true).send((eip1559 ? eip1559Transaction : legacyTransaction)).then(response => {
            this.setState({
                currentMintStatus: "notStarted",
            });
            this.updateIsApproved();
        }).catch(e => {
            if (e.code === -32602) {
                this.handleApproveBtnOnClick(false);
            } else {
                this.setState({
                    currentMintStatus: "notStarted",
                });
                console.log(e);
            }
        });
    }

    handleMintBtnOnClick = (eip1559) => {
        if (this.state.selectedGhost1 === "" || this.state.selectedGhost2 === "") {
            return;
        }
        this.setState({
            currentMintStatus: "approving"
        });
        const eip1559Transaction = {
            from: this.props.connectedAccount,
        };
        const legacyTransaction = {
            from: this.props.connectedAccount,
            type: "0x00",
            maxFeePerGas: null,
            maxPriorityFeePerGas: null
        };
        contractInstanceSuperGhosts.methods.mintSuperGhost(this.state.selectedGhost1, this.state.selectedGhost2).send((eip1559 ? eip1559Transaction : legacyTransaction)).then(response => {
            this.setState({
                currentMintStatus: "notStarted",
            });
            this.updateOwnedGhosts();
            this.setState({
                selectedGhost1: "",
                selectedGhost2: ""
            });
        }).catch(e => {
            if (e.code === -32602) {
                this.handleMintBtnOnClick(false);
            } else {
                this.setState({
                    currentMintStatus: "notStarted",
                });
                console.log(e);
            }
        });
    }

    updateIsApproved = () => {
        contractInstance.methods.isApprovedForAll(this.props.connectedAccount, contractaddressSuperGhosts).call().then(response => {
            this.setState({ isApproved: response });
        });
    }

    updateOwnedGhosts = () => {
        contractInstance.methods.tokensOfOwner(this.props.connectedAccount).call().then(response => {
            this.setState({ ownedGhosts: response });
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            if (this.props.isWeb3Connected) {
                if (typeof window.ethereum !== 'undefined' && (window.ethereum.chainId == allowedChainId || window.ethereum.chainId == allowedChainId2)) {
                    this.updateIsApproved();
                    this.updateOwnedGhosts();
                    contractInstanceSuperGhosts.methods.saleIsActive().call().then(response => {
                        this.setState({ contractAllowsMinting: response });
                    });
                } else {
                    alert("Please connect to the Avalanche C-Chain");
                }
            }
        }
    }

    componentDidMount = () => {
        if (typeof window.ethereum !== 'undefined' && this.props.isWeb3Connected) {
            if (window.ethereum.chainId == allowedChainId || window.ethereum.chainId == allowedChainId2) {
                contractInstanceSuperGhosts.methods.saleIsActive().call().then(response => {
                    this.setState({ contractAllowsMinting: response });
                    this.updateIsApproved();
                });
                this.updateOwnedGhosts();
            } else {
                alert("Please connect to the Avalanche C-Chain");
            }
        }
    }

    renderButton = () => {
        if (!this.props.isWeb3Connected) {
            return <div><br /><h2>Please connect your Metamask</h2></div>
        } else if (!this.state.contractAllowsMinting) {
            return <button disabled="disabled" class="superGhostMint-btn">Minting is not enabled</button>
        } else if (this.state.isApproved) {
            return <button onClick={() => this.handleMintBtnOnClick(true)} class="superGhostMint-btn">Mint Super Ghost</button>;
        } else {
            return <button onClick={() => this.handleApproveBtnOnClick(true)} class="superGhostMint-btn">Approve Ghosts</button>;
        }
    }

    render() {
        return (
            <section id="superGhostsMint" class=" align-items-center justify-content-center">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8">
                            <h1>Super Ghosts</h1>
                            <h2><span>The next step for your Ghosts</span></h2>
                        </div>
                    </div>

                    <div class="row gy-4 mt-5 justify-content-center d-flex align-items-center ">
                        <h2 class="infoText">Super Ghosts is a separate collection from the normal Ghosts. The theoretical max supply is 1250 if every normal Ghost is merged into a Super Ghost.</h2>
                        <br />
                        <h2><span>How do Super Ghosts work?</span></h2><br />
                        <h2 class="infoText">2 Avax Ghosts will need to be <span>BURNED</span> in order to mint 1 Super Ghost. Which two Ghosts are burned doesn't matter for the rarity of the Super Ghost. Will you be <span>lucky</span> and get a rare one?</h2>
                    </div>

                    <div class="row gy-4 mt-5 justify-content-center align-items-center">

                        <div class="col-md-3">
                            <div class="icon-box">
                                <img class="superGhostImage" src={this.state.selectedGhost1 !== "" ? process.env.PUBLIC_URL + "/img/image" + this.state.selectedGhost1 + ".png" : ""} />
                                <select id="selectedGhost1" onChange={(event) => this.onGhostSelect(1, event.target)} class="form-select">
                                    <option selected disabled hidden>Select Ghost 1</option>
                                    {this.getGhostList()}
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="icon-box">
                                <h1>+</h1>
                            </div>
                        </div><div class="col-md-3">
                            <div class="icon-box">
                                <img class="superGhostImage" src={this.state.selectedGhost2 !== "" ? process.env.PUBLIC_URL + "/img/image" + this.state.selectedGhost2 + ".png" : ""} />
                                <select id="selecteGhost2" onChange={(event) => this.onGhostSelect(2, event.target)} class="form-select">
                                    <option selected disabled hidden>Select Ghost 2</option>
                                    {this.getGhostList()}
                                </select>
                            </div>
                        </div>
                        {this.renderButton()}
                    </div>
                </div>
            </section >
        );
    }
}