import "./Navigation.css";
import React from "react";

export default class Navigation extends React.Component {

    handleNavOnClick = (event) => {

        document.getElementById("navHome").classList.remove("active");
        document.getElementById("navMint").classList.remove("active");
        document.getElementById("navTeam").classList.remove("active");
        document.getElementById("navSuperghosts").classList.remove("active");
        document.getElementById("navRoadmap").classList.remove("active");
        document.getElementById("navSocials").classList.remove("active");
        document.getElementById(event.target.id).classList.add("active");
    }
    componentDidMount = () => {
        if (this.props.returnHome) {
            this.handleNavOnClick({ target: { id: "navSuperghosts" } });
        } else if (typeof window.location.href.split("/")[3].split("#")[1] !== "undefined") {
            this.handleNavOnClick({ target: { id: "nav" + window.location.href.split("/")[3].split("#")[1].charAt(0).toUpperCase() + window.location.href.split("/")[3].split("#")[1].slice(1) } })
        }
    }
    render() {
        return (
            <header id="header" class="fixed-top ">
                <div class="container d-flex align-items-center justify-content-lg-between">

                    <h1 class="logo me-auto me-lg-0"><a href="index.html">Avax Ghosts<span>.</span></a></h1>

                    <nav id="navbar" class="navbar order-last order-lg-0">
                        <ul>
                            <li><a id="navHome" class="nav-link scrollto active" onClick={this.handleNavOnClick} href={this.props.returnHome ? "/#home" : "#home"}>Home</a></li>
                            <li><a id="navMint" class="nav-link scrollto" onClick={this.handleNavOnClick} href={this.props.returnHome ? "/#mint" : "#mint"}>Mint</a></li>
                            <li><a id="navSuperghosts" class="nav-link scrollto" onClick={this.handleNavOnClick} href={this.props.returnHome ? "/#superghosts" : "#superghosts"}>Super Ghosts</a></li>
                            <li><a id="navRoadmap" class="nav-link scrollto" onClick={this.handleNavOnClick} href={this.props.returnHome ? "/#roadmap" : "#roadmap"}>Roadmap</a></li>
                            <li><a id="navTeam" class="nav-link scrollto" onClick={this.handleNavOnClick} href={this.props.returnHome ? "/#team" : "#team"}>Team</a></li>
                            <li><a id="navSocials" class="nav-link scrollto" onClick={this.handleNavOnClick} href={this.props.returnHome ? "/#socials" : "#socials"}>Socials</a></li>
                        </ul>
                    </nav>

                    <button onClick={this.props.connectWeb3} class="connect-btn scrollto">{this.props.isWeb3Connected ? this.props.getConnectedAccount() : "Connect"}</button>
                </div>
            </header>
        );
    }
}
