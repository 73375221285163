import "./Home.css";
import React from "react";
import ghosts from "../img/ghosts.gif";
export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            randomImage1: this.getRandomGhostURL(),
            randomImage2: this.getRandomGhostURL(),
        }
    }
    renderGhost(responsive, url) {
        return (
            <div class="col-md-3">
                <div class="icon-box">
                    <img
                        class={responsive ? "ghostImage responsiveGhost" : "ghostImage"}
                        src={url}
                        alt={"GhostImage"}
                    />
                </div>
            </div>
        );
    }

    getRandomGhostURL() {
        const random = Math.floor(Math.random() * 1000);
        return process.env.PUBLIC_URL + "/img/image" + random + ".png";
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        return (
            <section id="home" class="d-flex align-items-center justify-content-center">
                <div class="container">

                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-8">
                            <h1>Unique Collectable Digital Ghosts<span>.</span></h1>
                            <h2>2'500 unique ghosts. With over 100 different assets, no two ghosts will ever be the same.</h2>
                        </div>
                    </div>
                    <div class="row gy-4 mt-5 justify-content-center">
                        {this.renderGhost(false, this.state.randomImage1)}
                        {this.renderGhost(false, ghosts)}
                        {this.renderGhost(true, this.state.randomImage2)}
                    </div>
                </div>
            </section>
        );
    }
}