import "./Team.css";
import React from "react";
import snowBallImage from "../img/image800.png";
import melionkaImage from "../img/image1277.png";
export default class Team extends React.Component {

    render() {
        return (
            <section id="team" class="team">
                <div class="container">

                    <div class="section-title">
                        <h2>Team</h2>
                        <p>The team behind ghosts</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div class="member">
                                <div class="member-img">
                                    <img src={melionkaImage} class="img-fluid" alt="" />
                                    <div class="social">
                                        <a href="https://twitter.com/Melionka_eth" rel="noreferrer" target="_blank"><i class="bi bi-twitter"></i></a>
                                    </div>
                                </div>
                                <div class="member-info">
                                    <h4>Melionka</h4>
                                    <span>Community Manager </span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 d-flex align-items-stretch">
                            <div class="member">
                                <div class="member-img">
                                    <img src={snowBallImage} class="img-fluid" alt="" />
                                    <div class="social">
                                    </div>
                                </div>
                                <div class="member-info">
                                    <h4>Snowball</h4>
                                    <span>Lead Developer & Art Design</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}