import "./Roadmap.css";
import React from "react";
import roadmap from "../img/roadmap.png"
export default class Roadmap extends React.Component {

    render() {
        return (
            <section id="roadmap" class="roadmap">
                <img id="roadmapImg" alt="Roadmap " src={roadmap}></img>
            </section>
        );
    }
}